import { Component, OnInit } from '@angular/core';
import { DhukanDataService } from '../services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
declare var google;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Dashboard";


  totalmember: number = 0;
  totalamount: number = 0;

  



  constructor(private appService: DhukanDataService, public router: Router, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService,
    private loginService: LoginDataService, private datePipe: DatePipe) {

    this.fnServiceChanges();
  }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }



  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  fnOnInitForm() {
    this.fngetDasboard();
  }

  ngOnInit() {

    if (this.loginService.fnCheckUser()) {
      this.fnCheckPrivilage();
      if (this.isview) {
        this.isloading = true;
        this.fnOnInitForm();
      }
      else {
        this.fnUserFailedForm();
      }
    }
    else {
      this.loginService.fnGetUserRoleList();
    }

  }


  fngetDasboard() {

    var data = {
      
    }
    this.appService.getDashboard(data)
    .subscribe(
      (res)=>{              
        if(res.json().status==200){                          
         var _res=res.json().result;

         console.log(_res)

         if(this.loginService.fnGetLoginType()==="ADMIN"){
          let summaryColl=_res[0];
          if(summaryColl.length>0){
           this.totalmember=summaryColl[0].totalmember;
           this.totalamount=summaryColl[0].totalamount;      
          }
         }
         else  if(this.loginService.fnGetLoginType()==="MEMBER"){
          let summaryColl=_res[0];
          if(summaryColl.length>0){
            this.totalmember=summaryColl[0].totalmember;
            this.totalamount=summaryColl[0].totalamount;         
          }
         }
       

         

        }
        else if(res.json().status==400){                          
          this.toastr.error('Error', res.json().err_field);
        }                          
    },
      (err)=>{                        
        this.toastr.error('Error', err);
     })
  }

}
