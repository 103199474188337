import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddmemberComponent } from './addmember/addmember.component';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']

})

export class MemberComponent implements OnInit {


  memberColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Member";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Code', field: 'membercode',    },
    { headerName: 'Member', field: 'membername',colcls:"wid250" },
    { headerName: 'Mobileno', field: 'mobileno' },
    { headerName: 'Refered By', field: 'referredbyname' },
    { headerName: 'Join Date', field: 'joindt',type:'date',  colcls:"wid150"},
    { headerName: 'PaymentStatus', field: 'paymentstatus' },
    { headerName: 'Paid Amount', field: 'paidamount',type:'decimal',  colcls:"wid150" },
    { headerName: 'EntryBy', field: 'createdbyname',  colcls:"wid150" },
    { headerName: 'EntryOn', field: 'createdon',type:'datetime',  colcls:"wid150" },
  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };


  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private productFindService: NgbModal, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetMember();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }




  fngetMember() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'userid': 0,
    }
    this.appService.getMember(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.memberColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddMember() {
    try {

      const modalRef = this.productFindService.open(AddmemberComponent, { size: <any>'xl', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.memberid = 0;
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        this.fngetMember()
      });


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditMember(pBO) {
    try {
      var deptid = pBO.memberid
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/ViewMember'], navigationExtras);

      // var deptid = pBO.memberid

      // const modalRef = this.productFindService.open(AddmemberComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
      // modalRef.componentInstance.memberid = deptid;
      // modalRef.componentInstance.ngOnInit();
      // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      //   this.fngetMember()
      // });
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(pBO) {
    try {
      var deptid = pBO.memberid
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete member?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnDeleteMember(deptid)
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteMember(deptid) {
    var data = {
      'memberid': deptid,
    }
    this.appService.fnDeleteMember(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The Member has been deleted successfully.");

            // this.fnInsertActivityLog('DELETE', deptid, this.fnGetDescription(deptid));

            this.fngetMember();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'Userlist',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }



  private fnSetDataTable() {
  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.memberColl.findIndex(sku => sku.userid === id);

    if (index) {
      return this.memberColl[index].membercode;
    }
    else {
      return "";
    }

  }
  public fnSearchDatatable(value) {


  }
  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

      return true;
    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
