import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddmemberComponent } from '.././addmember/addmember.component';
// 

@Component({
  selector: 'app-treemember',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './treemember.component.html',
  styleUrls: ['./treemember.component.css']
})
export class TreememberComponent implements OnInit {
  typeColl: any = [];
  treetype:any="horizontal"

  memberColl: any = [];
  minmember: any = 0;
  maxmember: any = 0;

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "User";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Code', field: 'membercode' },
    { headerName: 'Member', field: 'membername',colcls:"wid250" },
    { headerName: 'Mobileno', field: 'mobileno',colcls:"wid100" },
    { headerName: 'Refered By', field: 'referredbyname',colcls:"wid150" },
  ]

  gridOptions = {
    enableactioncol: true,
    columnDefs: this.columnDefs,
  };

  nodes: any = [];


  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private productFindService: NgbModal, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetMember();

    this.fnGetUserType();

  }

  fnGetUserType() {
    this.typeColl = []

    this.typeColl.push({ displayname: 'Vertical View', value: 'vertical' })
    this.typeColl.push({displayname:'Horizontal View',value:'horizontal'})
  }


  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }




  fngetMember() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'memberid': 0,
    }
    this.appService.getTreeMember(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            var result = res.json().result;
            this.memberColl = result[0];

            if (result.length > 1) {
              this.minmember = result[1][0].minmember;
              this.maxmember = result[1][0].maxmember;
            }

            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  private fnSetDataTable() {

    var data = this.fnAddChild(this.minmember,0);

    this.nodes = data;

    console.log(data)

    // for (let i = 1; i < this.maxmember; i++) {

    //   var pColl=this.memberColl.filter(a=>a.memberlevel===i);

    //   console.log(pColl)

    //   for(let abo of pColl){
    //     var data = {
    //       name: abo.membername,
    //       cssClass: 'ngx-org-ceo',
    //       image: '',
    //       title:  abo.mobileno,
    //       childs: [


    //       ]
    //     }
    //     this.nodes.push(data);
    //   }


    // }





  }

  fnAddChild(level,referredby) {
    var data = [];
    var pColl = this.memberColl.filter(a => a.memberlevel === level && a.referredby===referredby);
    for (let abo of pColl) {
      data.push({
        name: abo.membername,
        cssClass: 'ngx-org-ceo',
        image: abo.picurl,
        title: abo.mobileno,
        childs:level<=this.maxmember?this.fnAddChild(level + 1,abo.memberid):[]
      })
    }

    return data;
  }

 
  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.memberColl.findIndex(sku => sku.userid === id);

    if (index) {
      return this.memberColl[index].productgroup;
    }
    else {
      return "";
    }

  }
  public fnSearchDatatable(value) {


  }
  fnInsertActivityLog(action, refno, desc) {
    try {
      // var data = {
      //   logtype: action,
      //   logat: this.formname,
      //   refnum: refno,
      //   logdescr: desc,
      //   logby: this.loginService.fnGetModUserId(),
      // }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res) => {
      //   },
      //   (err) => {

      //   });

      return true;
    } catch {

    }
  }



  private fnLoaddata() {

  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
