import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-addmember',
  templateUrl: './addmember.component.html',
  styleUrls: ['./addmember.component.css']
})
export class AddmemberComponent implements OnInit {

  @ViewChildren('inputfirst') firstipcontrol;

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  memberColl: any = [];
  referalmemberColl: any = [];
  paymentmodeColl: any = [];

  memberroleColl: any = [];
  @Input() memberid: number = 0
  @Output() emitData = new EventEmitter();

  show: boolean;

  isenablereference: boolean = false

  memberform = new FormGroup({
    memberid: new FormControl(null),
    membercode: new FormControl(null, Validators.required),
    membername: new FormControl('', Validators.required),
    mobileno: new FormControl('', Validators.required),
    address: new FormControl(''),
    city: new FormControl(''),
    joindt: new FormControl(null, Validators.required),
    referredby: new FormControl(null, Validators.required),
    ispaid: new FormControl(false),
    paymentmodeid: new FormControl(null, Validators.required),
    paymentdate: new FormControl(null, Validators.required),
    paidamount: new FormControl(null, Validators.required),
    remarks: new FormControl(''),
    pwd: new FormControl(''),
    memberimage: new FormControl(null),
    picurl: new FormControl(''),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Member";

  //set image
  image;
  strImage: any;
  picurl: string = "";
  picname: string = "";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private datePipe: DatePipe,
    public router: Router, private toastr: ToastrService, private activeModal: NgbActiveModal, private loginService: LoginDataService) {

    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    this.loginService.fnSetFirstControlFocus('txtmembername');

  }
  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.memberform.reset();
      this.fnInitializeUserFormGroup();

      if (this.memberid > 0) {
        this.getMember();
      }

      this.getReferalMember();
      this.getPaymentmode();

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.memberid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeUserFormGroup() {
    this.memberform.setValue({
      memberid: 0,
      membercode: 'New',
      membername: '',
      mobileno: '',
      address: '',
      city: '',
      joindt: new Date(),
      referredby: null,
      ispaid: false,
      paymentmodeid: null,
      paymentdate: null,
      paidamount: null,
      remarks: '',
      pwd: '',
      picurl: '',
      memberimage: null,
      inactive: false,
    })
  }

  //Get 
  getMember() {
    var data = {
      'memberid': this.memberid,
    }
    this.appService.getMember(data)
      .subscribe(
        (res) => {
          this.memberColl = res.json().result;
          var editdata = this.memberColl[0];
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  getReferalMember() {
    var data = {
      'memberid': this.memberid,
    }
    this.appService.getReferalMember(data)
      .subscribe(
        (res) => {
          var result = res.json().result;
          this.referalmemberColl = result[0]


          if (result.length > 1) {
            if (result[1][0].enablereference === 1) {

            }
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  getPaymentmode() {
    var data = {
      'memberid': this.memberid,
    }
    this.appService.getPaymentmode(data)
      .subscribe(
        (res) => {
          this.paymentmodeColl = res.json().result;

          if (this.paymentmodeColl.length > 0 && this.memberid === 0) {
            this.memberform.patchValue({ 'paymentmodeid': this.paymentmodeColl[0].paymentmodeid })
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnSetValidattor() {
    if (this.memberform.get('ispaid').value) {
      this.memberform.controls['referredby'].setValidators(Validators.required);
      this.memberform.controls['paymentmodeid'].setValidators(Validators.required);
      this.memberform.controls['paymentdate'].setValidators(Validators.required);
      this.memberform.controls['paidamount'].setValidators(Validators.required);

    }
    else {
      this.memberform.controls['referredby'].clearValidators();
      this.memberform.controls['referredby'].setErrors(null);
      this.memberform.controls['referredby'].setValidators(null);

      this.memberform.controls['paymentmodeid'].clearValidators();
      this.memberform.controls['paymentmodeid'].setErrors(null);
      this.memberform.controls['paymentmodeid'].setValidators(null);

      this.memberform.controls['paymentdate'].clearValidators();
      this.memberform.controls['paymentdate'].setErrors(null);
      this.memberform.controls['paymentdate'].setValidators(null);

      this.memberform.controls['paidamount'].clearValidators();
      this.memberform.controls['paidamount'].setErrors(null);
      this.memberform.controls['paidamount'].setValidators(null);

      this.memberform.patchValue({ paymentmodeid: null })
      this.memberform.patchValue({ paymentdate: null })
      this.memberform.patchValue({ paidamount: null })

    }




  }


  fnSetValidation() {
    let _ischeck = false;
    if (this.loginService.fnCompareDateValidation(new Date(), this.memberform.get('joindt').value)) {

      this.memberform.controls['joindt'].setErrors({ 'notfound': true });
      _ischeck = true;
    }

    return _ischeck;
  }


  //Save Btn Click
  async fnSaveBtnClick() {
    try {
      await this.fnSetValidattor();


      this.submitted = true;
      this.successfully = false;

      //Validate
      if (this.memberform.valid) {

        if (this.fnSetValidation()) {
          // this.saveloading=false
          return;
        }



        //Check
        var data = {
          'memberid': this.memberid,
          'membercode': this.memberform.get('membercode').value,
          'membername': this.memberform.get('membername').value,
          'mobileno': this.memberform.get('mobileno').value,
          'address': this.memberform.get('address').value,
          'city': this.memberform.get('city').value,
          'joindt': this.fnFormatDatetime(this.memberform.get('joindt').value),
          'referredby': this.memberform.get('referredby').value,
          'ispaid': this.memberform.get('ispaid').value,
          'paymentmodeid': this.memberform.get('paymentmodeid').value,
          'paymentdate': this.fnFormatDatetime(this.memberform.get('paymentdate').value),
          'paidamount': this.memberform.get('paidamount').value,
          'remarks': this.memberform.get('remarks').value,
          'pwd': this.memberform.get('pwd').value,
          'memberimage': this.strImage,
          'picurl': this.picurl,
          'inactive': this.memberform.get('inactive').value,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckMember(data).subscribe(
          (res) => {
            console.log(res.json())
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.memberform.get('memberid').value > 0) {
                //Update Department
                this.fnSetMember(data);
              }
              else {
                //Insert Department
                this.fnInsertMember(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.memberform.controls['membername'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertMember(data) {

    this.appService.fnInsertMember(data).subscribe(
      (res) => {

        if (res.json().result != "") {
          this.toastr.success('', "The new Member has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.memberid);

          this.fnCloseUser();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetMember(data) {

    this.appService.fnSetMember(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Member has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.memberid, data.membername);

          this.fnCloseUser();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseUser() {
    try {

      this.emitData.emit(true);
      this.activeModal.close(true);
      //this.router.navigate(['/User']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    deptBO.joindt = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.joindt));
    deptBO.paymentdate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.paymentdate));

    this.memberform.setValue({
      memberid: deptBO.memberid,
      membercode: deptBO.membercode,
      membername: deptBO.membername,
      mobileno: deptBO.mobileno,
      address: deptBO.address,
      city: deptBO.city,
      joindt: deptBO.joindt,
      referredby: deptBO.referredby,
      ispaid: deptBO.ispaid,
      paymentmodeid: deptBO.paymentmodeid,
      paymentdate: deptBO.paymentdate,
      paidamount: deptBO.paidamount,
      remarks: deptBO.remarks,
      picurl: deptBO.picurl,
      memberimage: null,
      pwd: deptBO.password,
      inactive: deptBO.inactive,
    });


    this.picurl = deptBO.picurl

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }
  fnShowpassword(event) {

    this.show = !this.show;
  }


  //Image Import
  changeListener($event): void {
    try {
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }

  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  fnPaymentChange(event) {


    // if(event.checked===true){
    //   this.memberform.controls['referredby'].setValidators(Validators.required);
    //   this.memberform.controls['paymentmodeid'].setValidators(Validators.required);
    //   this.memberform.controls['paymentdate'].setValidators(Validators.required);
    //   this.memberform.controls['paidamount'].setValidators(Validators.required);
    // }
    // else{
    //   this.memberform.controls['referredby'].clearValidators();
    //   this.memberform.controls['referredby'].setErrors(null);
    //   this.memberform.controls['referredby'].setValidators(null);

    //   this.memberform.controls['paymentmodeid'].clearValidators();
    //   this.memberform.controls['paymentmodeid'].setErrors(null);
    //   this.memberform.controls['paymentmodeid'].setValidators(null);

    //   this.memberform.controls['paymentdate'].clearValidators();
    //   this.memberform.controls['paymentdate'].setErrors(null);
    //   this.memberform.controls['paymentdate'].setValidators(null);

    //   this.memberform.controls['paidamount'].clearValidators();
    //   this.memberform.controls['paidamount'].setErrors(null);
    //   this.memberform.controls['paidamount'].setValidators(null);

    //   this.memberform.patchValue({ paymentmodeid: null })
    //   this.memberform.patchValue({ paymentdate: null })
    //   this.memberform.patchValue({ paidamount: null })

    // }
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }

  public decline() {
    this.activeModal.close(false);
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex)
    this.toastr.warning('Internal Error', ex);
  }






}
