import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-earningreport',
  templateUrl: './earningreport.component.html',
  styleUrls: ['./earningreport.component.css']
})
export class EarningreportComponent implements OnInit {




  memberColl: any = [];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "User";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Code', field: 'membercode' },
    { headerName: 'Member', field: 'membername',colcls:"wid250" },
    { headerName: 'Mobileno', field: 'mobileno',colcls:"wid100"  },
    { headerName: 'Refered By', field: 'referredbyname',colcls:"wid150" },
    { headerName: 'Join Date', field: 'joindt',type:'date',colcls:"wid150" },
    { headerName: 'PaymentStatus', field: 'paymentstatus' },
    // { headerName: 'Paid Amount', field: 'paidamount',type:'decimal' },
    { headerName: 'EntryBy', field: 'createdbyname' },
    { headerName: 'EntryOn', field: 'createdon',type:'datetime',colcls:"wid150" },
  ]

  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
  };

  fromdate: Date = null;
  todate: Date = null;

  constructor(private appService: DhukanDataService, public router: Router, private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationDialogService, private productFindService: NgbModal, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetMember();
  }

  fnCheckPrivilage() {

    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }




  fngetMember() {

    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'fromdate': this.fnFormatDatetime(this.fromdate),
      'todate': this.fnFormatDatetime(this.todate),
      'filter': "MEMBERDETAILSREPORT",
    }
    this.appService.getReportMember(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.memberColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  fnActionExportClick() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'MemberDetailsReport',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    // this.gridApi.exportDataAsCsv(params);
  }



  private fnSetDataTable() {
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }



  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnGetDescription(id) {
    const index = this.memberColl.findIndex(sku => sku.userid === id);

    if (index) {
      return this.memberColl[index].membername;
    }
    else {
      return "";
    }

  }
  public fnSearchDatatable(value) {


  }
  fnInsertActivityLog(action, refno, desc) {
    try {
      // var data = {
      //   logtype: action,
      //   logat: this.formname,
      //   refnum: refno,
      //   logdescr: desc,
      //   logby: this.loginService.fnGetModUserId(),
      // }


      // this.appService.fnInsertActivity(data).subscribe(
      //   (res) => {
      //   },
      //   (err) => {

      //   });

      return true;
    } catch {

    }
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }







}
