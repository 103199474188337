
export const AppSettings = {
  //base_url: 'http://13.127.71.165:7200/',
  //base_url: 'http://200.1.50.102:7003/',
  //base_url: 'http://192.168.0.181:7003/',
 base_url: 'https://apijocheetrust.renoinfo.in/',

 //base_url: 'http://103.212.120.163:8003/',

  //User
  insertUserUrl: 'user/insertuser',
  setUserUrl: 'user/setuser',
  checkUserUrl: 'user/checkuser',
  getUserUrl: 'user/getuser',
  deleteUserUrl: 'user/deleteuser',
  getLoginUserUrl: 'user/getloginuser',
  getUserRoleRightsUserUrl: 'user/getuserrolerightsbyuserid',
  getLoginMemberUrl: 'user/getloginmember',

  //Street
  insertStreetUrl: 'street/insertstreet',
  setStreetUrl: 'street/setstreet',
  checkStreetUrl: 'street/checkstreet',
  getStreetUrl: 'street/getstreet',
  deleteStreetUrl: 'street/deletestreet',



  //Member
  insertMemberUrl: 'member/insertmember',
  setMemberUrl: 'member/setmember',
  checkMemberUrl: 'member/checkmember',
  getMemberUrl: 'member/getmember',
  getReferalMemberUrl: 'member/getreferalmember',
  deleteMemberUrl: 'member/deletemember',
  getPaymentmodeUrl: 'member/getpaymentmode',
  getTreeMemberUrl: 'member/gettreemember',
  getDashboardUrl: 'member/getdashboard',
  setMemberPasswordUrl: 'member/setmemberpassword',

  getReportMemberUrl: 'member/getreportmember',
  
  //Activitylog
  insertActivitylogUrl: 'common/insertactivitylog',

}


