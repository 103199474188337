import { Component, OnInit } from '@angular/core';
import { LoginDataService } from '../services/dhukan/login-data.service';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangepasswordComponent } from '../components/membermenu/changepassword/changepassword.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  
  isuser: boolean = false;
  
  logintype: any = "";

  constructor(private loginService: LoginDataService,public router: Router, private productFindService: NgbModal,  private toastr: ToastrService,) {  
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
      this.userView = value                 
      if(this.userView.userBO){                
        this.userBO= this.userView.userBO  
       
      }
    
      this.fnCheckMenu();
      
    }); 
  }

  ngOnInit() {
    this.loginService.fnGetUserRoleList();
   
  }


  fnCheckMenu() {
  
    let type = "";
    type = this.loginService.fnGetLoginType();

    this.logintype = type;

  }


  fnEditPassword() {
    try {

      const modalRef = this.productFindService.open(ChangepasswordComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.memberid = this.loginService.userBO.memberid;
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        
      });
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnLogout(){
    sessionStorage.removeItem("tokentype");
    sessionStorage.removeItem("ttoken");
    this.router.navigate(['/Login']);
  }
 
  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }
 

}
