import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { ToastrService } from 'ngx-toastr';
import { ChangepasswordComponent } from '../components/membermenu/changepassword/changepassword.component';
import { LoginDataService } from '../services/dhukan/login-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  
  isuser: boolean = false;
  
  logintype: any = "";

  constructor(public router: Router,private loginService: LoginDataService, private productFindService: NgbModal,  private toastr: ToastrService) {
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
        this.userView = value                 
        if(this.userView.userBO){                
          this.userBO= this.userView.userBO                
        }      
        
      this.fnCheckMenu();
      
    }); 
  }

  ngOnInit() {
    
    if(sessionStorage.ttoken === undefined){
      this.router.navigate(['/Login']);
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

   
  }


  fnMenuClick() {
    var element = document.getElementById('navbarSupportedContent');
    element.classList.remove('in');
  }
 
  
  fnCheckMenu() {
  
    let type = "";
    type = this.loginService.fnGetLoginType();

    this.logintype = type;

  }


  fnEditPassword() {
    try {

      const modalRef = this.productFindService.open(ChangepasswordComponent, { size: <any>'l', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.memberid = this.loginService.userBO.memberid;
      modalRef.componentInstance.ngOnInit();
      modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        
      });
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnLogout(){
    sessionStorage.removeItem("tokentype");
    sessionStorage.removeItem("ttoken");
    this.router.navigate(['/Login']);
  }
 
  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }
 


}



