import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  @ViewChildren('inputfirst') firstipcontrol;

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  memberColl: any = [];
  mamberBO:any={}

  memberroleColl: any = [];
  @Input() memberid: number = 0
  @Output() emitData = new EventEmitter();

  show: boolean;
  shownew: boolean;

  isenablereference: boolean = false

  memberform = new FormGroup({
    memberid: new FormControl(null),
    oldpwd: new FormControl('',Validators.required),
    pwd: new FormControl('',Validators.required),
    newpwd: new FormControl('', Validators.required),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Member";


  constructor(private appService: DhukanDataService, private route: ActivatedRoute, private datePipe: DatePipe,
    public router: Router, private toastr: ToastrService, private activeModal: NgbActiveModal, private loginService: LoginDataService) {

    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngAfterViewInit() {
    this.loginService.fnSetFirstControlFocus('txtmembername');

  }
  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.memberform.reset();
      this.fnInitializeUserFormGroup();

      if (this.memberid > 0) {
        this.getMember();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.memberid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeUserFormGroup() {
    this.memberform.setValue({
      memberid: 0,
      oldpwd: '',
      pwd: '',
      newpwd: null,
    })
  }

  //Get 
  getMember() {
    var data = {
      'memberid': this.memberid,
    }
    this.appService.getMember(data)
      .subscribe(
        (res) => {
          this.memberColl = res.json().result;
          var editdata = this.memberColl[0];

          this.mamberBO = editdata;
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fnSetValidattor() {
    // if (this.memberform.get('ispaid').value) {
    //   this.memberform.controls['referredby'].setValidators(Validators.required);
    //   this.memberform.controls['paymentmodeid'].setValidators(Validators.required);
    //   this.memberform.controls['paymentdate'].setValidators(Validators.required);
    //   this.memberform.controls['paidamount'].setValidators(Validators.required);

    // }
    // else {
    //   this.memberform.controls['referredby'].clearValidators();
    //   this.memberform.controls['referredby'].setErrors(null);
    //   this.memberform.controls['referredby'].setValidators(null);

    //   this.memberform.controls['paymentmodeid'].clearValidators();
    //   this.memberform.controls['paymentmodeid'].setErrors(null);
    //   this.memberform.controls['paymentmodeid'].setValidators(null);

    //   this.memberform.controls['paymentdate'].clearValidators();
    //   this.memberform.controls['paymentdate'].setErrors(null);
    //   this.memberform.controls['paymentdate'].setValidators(null);

    //   this.memberform.controls['paidamount'].clearValidators();
    //   this.memberform.controls['paidamount'].setErrors(null);
    //   this.memberform.controls['paidamount'].setValidators(null);

    //   this.memberform.patchValue({ paymentmodeid: null })
    //   this.memberform.patchValue({ paymentdate: null })
    //   this.memberform.patchValue({ paidamount: null })

    // }

 



  }


  fnSetValidation() {
    let _ischeck = false;

    if (this.mamberBO.password !== this.memberform.get('oldpwd').value) {
      this.memberform.controls['oldpwd'].setErrors({ 'notfound': true });
      _ischeck = true;
    }
    else {
      this.memberform.controls['oldpwd'].clearValidators();
      this.memberform.controls['oldpwd'].setErrors(null);
      this.memberform.controls['oldpwd'].setValidators(null);
    }


    if (this.memberform.controls['pwd'].value !== this.memberform.controls['newpwd'].value) {
      _ischeck= true;
      this.memberform.controls['newpwd'].setErrors({ 'notfound': true });
      // this.memberform.controls.newpwd.setErrors({ validatePasswordConfirmation: true });
    }

    // if (this.loginService.fnCompareDateValidation(new Date(), this.memberform.get('joindt').value)) {

    //   this.memberform.controls['joindt'].setErrors({ 'notfound': true });
    //   _ischeck = true;
    // }

    return _ischeck;
  }


  //Save Btn Click
  async fnSaveBtnClick() {
    try {
      await this.fnSetValidattor();


      this.submitted = true;
      this.successfully = false;

      //Validate
      if (this.memberform.valid) {

        if (this.fnSetValidation()) {
          // this.saveloading=false
          return;
        }



        //Check
        var data = {
          'memberid': this.memberid,
          'pwd': this.memberform.get('pwd').value,
        }
        if (this.memberform.get('memberid').value > 0) {
          //Update Department
          this.fnSetMember(data);
        }
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert


  //Set
  fnSetMember(data) {

    this.appService.fnSetMemberPassword(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The Member has been updated successfully.");

          this.fnInsertActivityLog('EDITPASSWORD', this.memberid, data.membername);

          this.fnCloseUser();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseUser() {
    try {

      this.emitData.emit(true);
      this.activeModal.close(true);
      //this.router.navigate(['/User']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.memberform.setValue({
      memberid: deptBO.memberid,
      oldpwd: null,
      pwd: null,
      newpwd: null,
    });

    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }
  fnShowpassword(event) {

    this.show = !this.show;
  }

  fnShowNewpassword(event) {

    this.shownew = !this.shownew;
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }
  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        var dateParts = dt.split("-");
        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());
        return sdate;
      }

    } else {
      return null
    }

  }

  public decline() {
    this.activeModal.close(false);
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex)
    this.toastr.warning('Internal Error', ex);
  }






}
