import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-imageformatter',
  templateUrl: './imageformatter.component.html',
  styleUrls: ['./imageformatter.component.css']
})
export class ImageformatterComponent implements OnInit {

  params: any;
  agInit(params: any){
    this.params = params; 
  
  } 
  
  constructor( private productFindService: NgbModal) { }

  ngOnInit() {

  }

   
  //Zoom Product
  fnShowZoomProduct(skuid){
    // const modalRef = this.productFindService.open(ZoomproductComponent ,{ size: <any>'l' },);
    // modalRef.componentInstance.doctype ="" ;
    // modalRef.componentInstance.skuid=skuid;
    // modalRef.componentInstance.fnGetProductInfo();
    // modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
    
    // });
        
  }

}
