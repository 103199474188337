import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buttonformatter',
  templateUrl: './buttonformatter.component.html',
  styleUrls: ['./buttonformatter.component.css']
})
export class ButtonformatterComponent implements OnInit {
  private params: any;

  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler() {
    this.params.deleteclicked(this.params.value);
  }

  
}
