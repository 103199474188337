import { NgModule, } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { LoginComponent } from './components/login/login.component';



import { UserComponent } from './components/user/user.component';
import { AdduserComponent } from './components/user/adduser/adduser.component';
import { MemberComponent } from './components/member/member.component';
import { AddmemberComponent } from './components/member/addmember/addmember.component';
import { TreememberComponent } from './components/member/treemember/treemember.component';
import { ViewmemberComponent } from './components/member/viewmember/viewmember.component';
import { TreemembermenuComponent } from './components/membermenu/treemembermenu/treemembermenu.component';
import { ViewmembermenuComponent } from './components/membermenu/viewmembermenu/viewmembermenu.component';
import { MemberdetailsreportComponent } from './components/report/memberdetailsreport/memberdetailsreport.component';
import { PaymentreportComponent } from './components/report/paymentreport/paymentreport.component';
import { UnpaidmemberreportComponent } from './components/report/unpaidmemberreport/unpaidmemberreport.component';
import { EarningreportComponent } from './components/report/earningreport/earningreport.component';


const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'Dashboard', component: DashboardComponent },
  { path: 'Login', component: LoginComponent },  
  { path: 'User', component: UserComponent },
  { path: 'AddUser', component: AdduserComponent },  
  { path: 'Member', component: MemberComponent },
  { path: 'AddMember', component: AddmemberComponent },  
  { path: 'TreeMember', component: TreememberComponent },
  { path: 'ViewMember', component: ViewmemberComponent },
  { path: 'TreeMemberMenu', component: TreemembermenuComponent },
  { path: 'ViewMemberMenu', component: ViewmembermenuComponent },
  { path: 'MemberDetailsReport', component: MemberdetailsreportComponent },
  { path: 'PaymentReport', component: PaymentreportComponent },
  { path: 'UnPaidReport', component: UnpaidmemberreportComponent },
  { path: 'EarningsReport', component: EarningreportComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  //imports: [RouterModule.forRoot(routes, { useHash: false })],
  bootstrap: [AppComponent],
  exports: [RouterModule],
  //providers:[Location,{provide:LocationStrategy,useClass:PathLocationStrategy}]
})

export class AppRoutingModule { }
