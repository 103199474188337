import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { LoginDataService } from './services/dhukan/login-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewChecked  {
  
 
  title = 'RBOOK';

  islogin:boolean=false;

  constructor(private appService: LoginDataService, private bnIdle: BnNgIdleService) {
    this.bnIdle.startWatching(5000).subscribe((res) => {
      if (res) {
        sessionStorage.removeItem("ttoken");
        
        this.appService.fnCheckLogin();
      }
    })

    this.appService.fnCheckLogin();
  }

  ngOnInit() { 
    
   
  }

  ngAfterViewChecked(): void {

    setTimeout(() =>  this.islogin=this.appService.islogin );
     
  }

}
