import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-renogrid',
  templateUrl: './renogrid.component.html',
  styleUrls: ['./renogrid.component.css']
})
export class RenogridComponent implements OnInit {

  
  pageno: any = 25;

  pages = [25, 50, 100, 500];
  
  p: number = 1;

  

  filterMetadata = { count: 0 };

  @Input() formname: any = "";

  @Input() contentcoll: any = [];
  @Input() searchtxt: any = "";
  @Input() gridOptions = {};
  @Input() isedit:any=false;
  @Input() isdelete:any=false;

  @Output() editChanged = new EventEmitter<any>();
  @Output() deleteChanged = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  fnEditBtnClick(pBO,type) {
    pBO.modetype=type
    this.editChanged.emit(pBO)
  }

  fnDeleteBtnClick(pBO) {
    this.deleteChanged.emit(pBO)
  }

}
