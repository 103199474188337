import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | any;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private confirmationDialogService: ConfirmationDialogService) {};

  canDeactivate(component: ComponentCanDeactivate): boolean | any {
    // if there are no pending changes, just allow deactivation; else confirm first
    console.log(component.canDeactivate())

     return component.canDeactivate() ?
       true :
       this.fnConfirm();
      //confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
     // return  this.fnConfirm();
  }


  fnConfirm() {      
     return this.confirmationDialogService.confirm('Confirm..', 'Do you  want to leave this page?')
      .then((confirmed) => 
            {
              if(confirmed){
                return true;
              }          
              else{
                return false                
              }
             
          }        
      );     

      
  }


  fnShowErrorMsg(ex: any) {
    throw new Error("Method not implemented.");
  }

}