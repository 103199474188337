import { AppSettings } from '../constants/constants';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs'
import { catchError, map, share } from 'rxjs/operators';
import { HttpClient } from 'selenium-webdriver/http';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable(
   {
      providedIn: 'root'
   }
)
export class DhukanDataService {
   deviceInfo = null;
   ipAddress: any;

   constructor(private http: Http, private deviceService: DeviceDetectorService) {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.fnGetIpAddress();
      console.log(this.deviceInfo)
   }



   fnpostInputParams(url, params) {
      const headers = new Headers({
         //'Content-Type': "application/JSON",
         // 'token': (sessionStorage.rbookadtoken === undefined) ? '' : JSON.parse(sessionStorage.rbookadtoken)   

         'tokentype': (sessionStorage.tokentype === undefined) ? '' : sessionStorage.tokentype,               
         'token': (sessionStorage.ttoken === undefined) ? '' : sessionStorage.ttoken  ,  
         'authkey': (sessionStorage.authkey === undefined) ? 1 : JSON.parse(sessionStorage.authkey),                       
      });


      return this.http.post(AppSettings.base_url + url, params, { headers: headers });
   }








   //User 
   fnInsertUser(params) {

      return this.fnpostInputParams(AppSettings.insertUserUrl, params);
   }

   fnSetUser(params) {

      return this.fnpostInputParams(AppSettings.setUserUrl, params);
   }

   getUser(params) {

      return this.fnpostInputParams(AppSettings.getUserUrl, params);
   }
 
   fnDeleteUser(params) {

      return this.fnpostInputParams(AppSettings.deleteUserUrl, params);
   }

   fnCheckUser(params) {

      return this.fnpostInputParams(AppSettings.checkUserUrl, params);
   }

 
   getUserlogin(params) {

      return this.fnpostInputParams(AppSettings.getLoginUserUrl, params);
   }

   getMemberlogin(params) {

      return this.fnpostInputParams(AppSettings.getLoginMemberUrl, params);
   }

   //Street 
   fnInsertStreet(params) {

      return this.fnpostInputParams(AppSettings.insertStreetUrl, params);
   }

   fnSetStreet(params) {

      return this.fnpostInputParams(AppSettings.setStreetUrl, params);
   }


   getStreet(params) {

      return this.fnpostInputParams(AppSettings.getStreetUrl, params);
   }


   fnDeleteStreet(params) {

      return this.fnpostInputParams(AppSettings.deleteStreetUrl, params);
   }

   fnCheckStreet(params) {

      return this.fnpostInputParams(AppSettings.checkStreetUrl, params);
   }


   
   //Member 
   fnInsertMember(params) {

      return this.fnpostInputParams(AppSettings.insertMemberUrl, params);
   }

   fnSetMember(params) {

      return this.fnpostInputParams(AppSettings.setMemberUrl, params);
   }


   getMember(params) {

      return this.fnpostInputParams(AppSettings.getMemberUrl, params);
   }

   getTreeMember(params) {

      return this.fnpostInputParams(AppSettings.getTreeMemberUrl, params);
   }

   getReferalMember(params) {

      return this.fnpostInputParams(AppSettings.getReferalMemberUrl, params);
   }

   getPaymentmode(params) {

      return this.fnpostInputParams(AppSettings.getPaymentmodeUrl, params);
   }

   fnDeleteMember(params) {

      return this.fnpostInputParams(AppSettings.deleteMemberUrl, params);
   }

   fnCheckMember(params) {

      return this.fnpostInputParams(AppSettings.checkMemberUrl, params);
   }

   getDashboard(params) {

      return this.fnpostInputParams(AppSettings.getDashboardUrl, params);
   }

   fnSetMemberPassword(params) {

      return this.fnpostInputParams(AppSettings.setMemberPasswordUrl, params);
   }



   getReportMember(params) {

      return this.fnpostInputParams(AppSettings.getReportMemberUrl, params);
   }

   //Common
   fnGetIpAddress() {
      this.http.get('https://jsonip.com')
         .subscribe(data => {
            this.ipAddress = data.json()

         })

   }

   fnInsertActivity(data) {

      var dept_para = {
         logtype: data.logtype,
         logat: data.logat,
         refnum: data.refnum,
         logdescr: data.logdescr,
         logby: data.logby,
         ipaddress: this.ipAddress.ip,
         devicename: this.deviceInfo.browser + " " + this.deviceInfo.os,
      };


      return this.fnpostInputParams(AppSettings.insertActivitylogUrl, dept_para);
   }

}


